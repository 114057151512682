var altoPantalla = jQuery(window).height();
var anchoPantalla = jQuery(window).width();
var altoHeader = jQuery('header').outerHeight();

function altoFormMask() {
  jQuery('.searchContainer').height(altoPantalla - altoHeader).css('top', altoHeader -5);
}

function altoFormMaskMovil() {
  jQuery('.searchContainer').height(altoPantalla - altoHeader).css('top', altoHeader);
}

function altoMenuMask() {
  jQuery('.menuMobile').height(altoPantalla - altoHeader).css('margin-top', altoHeader);
}

function centrarTitular() {
  jQuery('.minItem').each(function(){
    var altoTitular = jQuery(this).find('strong').height() / 2;
    jQuery(this).find('strong').css('margin-top', - altoTitular);
  });
}

function igualarAltura() {
  jQuery('.catchAltura').each(function(){
    var guardaAltura = jQuery(this).outerHeight();
    jQuery(this).parent().find('.addAltura').height(guardaAltura);
  });
}

jQuery(document).ready(function(){

  // Precarga
  jQuery(window).load(function(){
  	jQuery('.loader').fadeOut('slow',function(){
    });
  });
  jQuery(window).bind('beforeunload', function(){
    jQuery('.loader').fadeIn('fast');
  });

  // Clases para lightbox de servicios
  jQuery('.galeriaServicios').each(function(i) {
    jQuery(this).find('a').attr('data-fancybox', 'galeria' + (i+1));
  });

  jQuery('#menu-menu-movil-1 .menu-item-has-children').hover(
    function() {
      jQuery(this).find('.sub-menu').fadeIn('fast');
    },
    function() {
      jQuery(this).find('.sub-menu').fadeOut('fast');
    }
  );

  // Despliega contenido con scroll
  jQuery(window).scroll( function(){
    jQuery('.hideme').each( function(i){
      var bottom_of_objecta = jQuery(this).offset().top + (jQuery(this).outerHeight()*.1);
      var bottom_of_windowa = jQuery(window).scrollTop() + jQuery(window).height();
      if( bottom_of_windowa > bottom_of_objecta ){
          TweenLite.to(this, 2, {y: 0, autoAlpha: 1, ease: Power4.easeOut});
      }
      else if( bottom_of_windowa < bottom_of_objecta ){
          TweenLite.to(this, 2, {y: 60, autoAlpha: 0, ease: Power4.easeOut});
      }
    });
  });

  // Crea estructura de colapsables
  jQuery('.panel-heading').each(function(i) {
      jQuery(this).attr('id', 'heading0' + (i+1));
  });
  jQuery('.panel-collapse').each(function(i) {
      jQuery(this).attr('id', 'collapse0' + (i+1));
  });
  jQuery('.panel-collapse').each(function(i) {
      jQuery(this).attr('aria-labelledby', 'heading0' + (i+1));
  });
  jQuery('.catchA').each(function(i) {
      jQuery(this).attr('href', '#collapse0' + (i+1));
  });
  jQuery('.catchA').each(function(i) {
      jQuery(this).attr('aria-controls', 'collapse0' + (i+1));
  });

  // Navegacion por scroll
  jQuery('.serviciosContainer').each(function(i) {
    jQuery(this).attr('id', 'servicio' + (i+1));
  });
  jQuery('#menu-menu-servicios a').each(function(i) {
    jQuery(this).attr('href', '#servicio' + (i+1));
  });
  jQuery('#menu-menu-servicios a').click(function(){
    jQuery('html, body').animate({
      scrollTop: jQuery( jQuery(this).attr('href') ).offset().top
    }, 500);
    return false;
  });

  // Menu servicios fixed
  jQuery(window).scroll(function(){
    if (jQuery(this).scrollTop() > 154) {
      jQuery('.hidden-xs #menu-menu-servicios').css({'position':'fixed','top':'25px'});
    } else {
      jQuery('.hidden-xs #menu-menu-servicios').css('position', 'relative');
    }
  });

  jQuery('.vitae').append('<small>Solo puedes subir archivos con las extensiones pdf, doc, docx y de un máximo de 5mb de peso.</small>');

  // Ajuste margen items de navegacion de slider principal
  var alturaNav = jQuery('.carousel-indicators').height() / 2;
  jQuery('.carousel-indicators').css('margin-top', alturaNav);

  // Timeline
  jQuery('.quienesSomosSec03 .nav-tabs').scrollingTabs({
    scrollToTabEdge: true
  });
  jQuery('.quienesSomosSec03 .nav-tabs li:first-child').addClass('active');
  jQuery('.quienesSomosSec03 .tab-content .tab-pane:first-child').addClass('in active');
  jQuery('.quienesSomosSec03 .nav-tabs a').each(function(i) {
    jQuery(this).attr('href', '#timeline0' + (i+1));
  });
  jQuery('.quienesSomosSec03 .tab-content .tab-pane').each(function(i) {
    jQuery(this).attr('id', 'timeline0' + (i+1));
  });

  // Crea id y clases para tabs
  jQuery('.infoGenContainer .nav-tabs li:first-child').addClass('active');
  jQuery('.infoGenContainer .tab-content .tab-pane:first-child').addClass('in active');
  jQuery('.infoGenContainer .nav li a').each(function(i) {
    jQuery(this).attr('href', '#tabitem0' + (i+1));
  });
  jQuery('.infoGenContainer .tab-content .tab-pane').each(function(i) {
    jQuery(this).attr('id', 'tabitem0' + (i+1));
  });

  // Crea estilos para tablas
  jQuery('.tab-content table').addClass('table-condensed table-striped');
  jQuery('.tab-content table tr:first-child').addClass('tableHead');

  // Crea estilos para accordion
  jQuery('.accordionProductos table').addClass('table-condensed table-striped');
  jQuery('.accordionProductos table tr:first-child').addClass('tableHead');

  // Agrega icono a vinculos con pdf
  jQuery('.contentContainer a[href$=".pdf"]').addClass('linkWithPdf');

  // Crea estructura de pagina de noticias
  jQuery('.landingNewsCont > div:first-child').addClass('notDestacada').removeClass('col-sm-4 notNormal');

  //Muestra buscador general
  jQuery('.despliegaBuscador').click(function(){
    jQuery('.searchContainer').fadeToggle('fast');
    jQuery('body').toggleClass('stopScrolling');
  });

  //Muestra menu mobile
  jQuery('.openMenuMobile').click(function(){
    jQuery('.menuMobile').fadeToggle('fast');
    jQuery('body').toggleClass('stopScrolling');
  });

  //cambia textos para selector de idiomas
  jQuery('.lang-es span').html('ES');
  jQuery('.lang-en span').html('EN');

  // Crea slider del home
  jQuery('.carousel').carousel();
  jQuery('.carousel-indicators li:first-child').addClass('active');
  jQuery('.carousel-inner .item:first-child').addClass('active');

  // Menu desplebale
  jQuery('#menu-menu-productos > li > a').addClass('itemPadre');
  jQuery('.itemPadre').parent().append('<span class="openMenu"><i class="ion-android-add"></i></span>');
  jQuery('.openMenu').click(function(){
    jQuery(this).toggleClass('active');
    jQuery(this).parent().find('.sub-menu').slideToggle(500);
  });

  // Landing proyectos destacados
  jQuery('.catchClass').eq(0).addClass('col-md-12 bigItem').removeClass('col-md-4 minItem');
  jQuery('.minItem .caractContainer > div').addClass('col-xs-6');
  jQuery('.minItem .caractContainer').addClass('row');

  // Crea slider de clientes
  jQuery('#logosSlider').imagesLoaded(function() {
    jQuery('#logosSlider').carouFredSel({
      responsive: true,
      items: {
        visible: {
          min: 1,
          max: 5
        }
      },
      prev: '#prevClientes',
      next: '#nextClientes',
      scroll: {
        items: 1,
        duration: 1000,
        pauseOnHover: true,
        easing: "quadratic"
      },
      swipe: {
        onTouch: true
      }
    });
  });

  // Crea slider de testimonios
  jQuery('#testimoniosSlider').imagesLoaded(function() {
    jQuery('#testimoniosSlider').carouFredSel({
      responsive: true,
      items: 1,
      prev: '#prevTestimonios',
      next: '#nextTestimonios',
      scroll: {
        items: 1,
        duration: 1000,
        pauseOnHover: true,
        easing: "quadratic"
      },
      swipe: {
        onTouch: true
      }
    });
  });

  // Crea slider de imagenes destacadas de contenido
  jQuery('#imagenesDestacadas').imagesLoaded(function() {
    jQuery('#imagenesDestacadas').carouFredSel({
      responsive: true,
      items: 1,
      prev: '#prevImgContenido',
      next: '#nextImgContenido',
      scroll: {
        items: 1,
        duration: 1000,
        pauseOnHover: true,
        easing: "quadratic"
      },
      swipe: {
        onTouch: true
      }
    });
  });

  // Crea slider de galeria de contenido
  jQuery('#galContenido').imagesLoaded(function() {
    jQuery('#galContenido').carouFredSel({
      width: '100%',
      height: 'variable',
      responsive: true,
      items: {
        height: 'variable',
        visible: {
          min: 1,
          max: 3
        }
      },
      prev: '#prevImgGalContenido',
      next: '#nextImgGalContenido',
      scroll: {
        items: 1,
        duration: 1000,
        pauseOnHover: true,
        easing: "quadratic"
      },
      swipe: {
        onTouch: true
      }
    });
  });

  // Crea slider valores
  // jQuery('#sliderValores').imagesLoaded(function() {
  //   jQuery('#sliderValores').carouFredSel({
  //     responsive: true,
  //     items: 1,
  //     pagination: "#sliderValoresCarouselPager",
  //     prev: '#prevItemValores',
  //     next: '#nextItemValores',
  //     scroll: {
  //       fx: 'fade',
  //       items: 1,
  //       duration: 1000,
  //       pauseOnHover: true,
  //       easing: 'quadratic'
  //     },
  //     swipe: {
  //       onTouch: true
  //     }
  //   });
  // });

  igualarAltura();
  altoMenuMask();
  centrarTitular();

  jQuery(window).resize(function(){
    igualarAltura();
    altoMenuMask();
    centrarTitular();
  });

  // Utilizado para pagina de contacto
  // if (location.hash === "#fiefre") {
  //   jQuery('.consulta select option:eq(1)').prop('selected', true);
  // }
  if (location.hash === "#ventas") {
    jQuery('.consulta select option:eq(0)').prop('selected', true);
  }
  if (location.hash === "#servicio-tecnico") {
    jQuery('.consulta select option:eq(1)').prop('selected', true);
  }
  if (location.hash === "#trabaja-con-nosotros") {
    jQuery('.contacto .noRightPadding').removeClass('active');
    jQuery('.contacto .noLeftPadding').addClass('active');
    jQuery('.contacto .tab-pane-contacto').removeClass('in active');
    jQuery('.contacto .tab-pane-trabajo').addClass('in active');
  }

  if(jQuery(window).width()<992){

    // HTML para menu movil
    jQuery('.menuMobile .menu-item-has-children').append('<i class="ion-android-add"></i>');
    jQuery('.menu-item-has-children i').click(function(){
      jQuery(this).parent().find('.sub-menu').slideToggle(500);
    });

  }
  if(jQuery(window).width()<768){

    jQuery('.hiddenMobile').remove();

    // Crea slider de imagenes de servicios
    jQuery('.galeriaServiciosMobile').imagesLoaded(function() {
      jQuery('.galeriaServiciosMobile').carouFredSel({
        responsive: true,
        items: 1,
        scroll: {
          items: 1,
          duration: 750,
          pauseOnHover: true,
          easing: "quadratic"
        },
        swipe: {
          onTouch: true
        }
      });
    });

    var itemsTimeline = jQuery('.scrtabs-tabs-fixed-container').width() - 60;
    jQuery('.quienesSomosSec03 .scrtabs-tabs-fixed-container li a').css('width', itemsTimeline);

    // Clases para accordion de pagina de productos
    jQuery('.accordionProductos .panel').each(function(i){
      jQuery(this).find('.panel-collapse').attr('id', 'collapse0'+ (i+1));
      jQuery(this).find('.collapseLink').attr('href', '#collapse0'+ (i+1));
    });
    jQuery('#collapse01').addClass('in');

    // Crea slider mobile de noticias
    jQuery('#homeNewsCarousel').imagesLoaded(function() {
      jQuery('#homeNewsCarousel').carouFredSel({
        responsive: true,
        auto: false,
        items: 1,
        pagination: "#homeNewsCarouselPager",
        scroll: {
          items: 1,
          duration: 1000,
          pauseOnHover: true,
          easing: 'quadratic'
        },
        swipe: {
          onTouch: true
        }
      });
    });

    // Crea slider mobile de marcas exclusivas
    jQuery('#marcasExclusivasCarousel').imagesLoaded(function() {
      jQuery('#marcasExclusivasCarousel').carouFredSel({
        responsive: true,
        auto: false,
        items: 1,
        prev: '#prevMarca',
        next: '#nextMarca',
        scroll: {
          items: 1,
          duration: 1000,
          pauseOnHover: true,
          easing: 'quadratic'
        },
        swipe: {
          onTouch: true
        }
      });
    });

    // Crea slider mobile de grupo fiefre
    jQuery('#grupoCarousel').imagesLoaded(function() {
      jQuery('#grupoCarousel').carouFredSel({
        responsive: true,
        auto: false,
        items: 1,
        prev: '#prevGrupo',
        next: '#nextGrupo',
        scroll: {
          items: 1,
          duration: 1000,
          pauseOnHover: true,
          easing: 'quadratic'
        },
        swipe: {
          onTouch: true
        }
      });
    });

  }
  else {

    jQuery('.hiddenDesktop').remove();

    // Agrega clases odd even
    jQuery('.serviciosContainer .singleServicios:even').addClass('even');
    jQuery('.serviciosContainer .singleServicios:odd').addClass('odd');
    jQuery('.singleServicios.odd .col-md-6').each(function(){
      jQuery(this).prependTo(this.parentNode);
    });

    // Crea slider de imagenes de servicios
    jQuery('.galeriaServicios').imagesLoaded(function() {
      jQuery('.galeriaServicios').carouFredSel({
        responsive: true,
        items: 1,
        scroll: {
          items: 1,
          duration: 600,
          pauseOnHover: true,
          easing: "quadratic"
        },
        swipe: {
          onTouch: true
        }
      });
    });

    // Calcula margen caja de texto Valores
    jQuery('#sliderValores .itemValores').each(function() {
      var alturaImagen = jQuery(this).find('.itemValoresImg').outerHeight();
      var alturaContenido = jQuery(this).find('.itemValoresContent').outerHeight();
      var crearMargen = alturaImagen - alturaContenido;
      jQuery(this).find('.itemValoresContent').css('margin-top', crearMargen / 2);
    });
    jQuery('#sliderValores .itemValores:even').addClass('even');
    jQuery('#sliderValores .itemValores:odd').addClass('odd');

  }

  jQuery('#BotonEnviarPorEmail').on('click',function(e){
    e.preventDefault();
    var url = jQuery('#EnviarPorEmailFORM').attr('action');
    var data = jQuery('#EnviarPorEmailFORM').serialize();
    console.log(data);

    jQuery.post(url,data,function(rr){
      if(rr.status){
        alert('El artículo se ha compartido exitosamente');
        jQuery('#myModal').modal('hide') ;
      } else {
        alert('Algo salió mal, inténtalo nuevamente');
      }
    },'json');

  });

});
